/* eslint-disable @next/next/no-img-element */
import React from 'react'
import { ImgResponsive } from '@/components/elements'
import { StylePartnerBox } from './style'
import classNames from 'classnames'

type PartnerBoxProps = {
  className?: string
  imageLg?: string
  imageMd?: string
  title: string
  width?: number
  height?: number
}

const PartnerBox = (props: PartnerBoxProps) => {
  const { className, imageLg = '', imageMd = '', title = '', width, height } = props
  const meageWrapper = classNames('p-12', className)
  return (
    <StylePartnerBox className={meageWrapper}>
      {imageLg && imageMd ? (
        <div className='flex h-full items-center justify-center'>
          <ImgResponsive
            src={imageLg}
            sourceImgSm={imageMd}
            sourceImgMd={imageLg}
            alt={title}
            loadingLazy
            width={width}
            height={height}
          />
        </div>
      ) : null}
    </StylePartnerBox>
  )
}

export default PartnerBox
