/* eslint-disable @next/next/no-img-element */
import classNames from 'classnames'
import React from 'react'
import { TitleShadow } from '@/components/modules'
import { ImgResponsive } from '@/components/elements'
import { StyleFocusBox } from './style'

type FocusBoxProps = {
  className?: string
  imageLg?: string
  imageMd?: string
  prologue?: string | React.ReactNode
  title?: string | React.ReactNode
  alt?: string
  subtitle?: string | React.ReactNode
  colorPrologue?: string
  colorTitle?: string
  colorSubtitle?: string
  description?: string | React.ReactNode
  borderless?: boolean
  subTitlePosition?: 'top' | 'bottom'
  rootTitleClassName?: string
  rootTitleStyle?: React.CSSProperties
}

const FocusBox = (props: FocusBoxProps) => {
  const {
    className,
    imageLg = '',
    imageMd = '',
    title = '',
    subtitle,
    prologue,
    colorPrologue,
    colorTitle,
    colorSubtitle,
    description,
    alt = '',
    borderless = false,
    subTitlePosition = 'bottom',
    rootTitleClassName,
    rootTitleStyle,
  } = props
  const meageWrapper = classNames('focus-el-list', className)
  const meageClsTitle = classNames('focus-el-content-inner', rootTitleClassName)
  return (
    <StyleFocusBox className={meageWrapper} borderWrapper={borderless}>
      <div className='focus-el-list-content focus-el-list-layout--link-button'>
        {imageLg && imageMd ? (
          <div className='focus-el-image'>
            <ImgResponsive
              src={imageLg}
              alt={alt}
              sourceImgSm={imageMd}
              sourceImgMd={imageLg}
              loadingLazy
            />
          </div>
        ) : null}
        <div className='focus-el-content'>
          <div className={meageClsTitle} style={rootTitleStyle}>
            <TitleShadow
              prologue={prologue}
              colorPrologue={colorPrologue}
              title={title}
              colorTitle={colorTitle}
              subtitle={subtitle}
              colorSubtitle={colorSubtitle}
              subTitlePosition={subTitlePosition}
            />
            {description}
          </div>
        </div>
      </div>
    </StyleFocusBox>
  )
}

export default FocusBox
