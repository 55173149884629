import styled from 'styled-components'

export const StyleFocusBox = styled.div<{ borderWrapper: boolean }>`
  position: relative;
  width: 100%;
  border: 0;
  border-radius: 0;
  box-shadow:
    ${(props) => (props.borderWrapper === true ? '1px' : 0)} 0 0 0 #dbdbdb,
    0 ${(props) => (props.borderWrapper === true ? '1px' : 0)} 0 0 #dbdbdb,
    ${(props) => (props.borderWrapper === true ? '1px' : 0)}
      ${(props) => (props.borderWrapper === true ? '1px' : 0)} 0 0 #dbdbdb,
    ${(props) => (props.borderWrapper === true ? '1px' : 0)} 0 0 0 #dbdbdb inset,
    0 ${(props) => (props.borderWrapper === true ? '1px' : 0)} 0 0 #dbdbdb inset;
  transition:
    background 0.3s,
    border 0.3s,
    border-radius 0.3s,
    box-shadow 0.3s,
    -webkit-box-shadow 0.3s;
  .focus-el-image {
    clip-path: inset(0 100% 0 0);
    -webkit-clip-path: inset(0 100% 0 0);
    transition: -webkit-clip-path 0.5s cubic-bezier(0.31, 0.29, 0.05, 0.96);
    transition: clip-path 0.5s cubic-bezier(0.31, 0.29, 0.05, 0.96);
    transition:
      clip-path 0.5s cubic-bezier(0.31, 0.29, 0.05, 0.96),
      -webkit-clip-path 0.5s cubic-bezier(0.31, 0.29, 0.05, 0.96);
  }
  img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .focus-el-list-content {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    .focus-el-image {
      position: relative;
      display: inline-block;
      width: 100%;
      vertical-align: top;
      overflow: hidden;
      img {
        display: block;
        transition: all 0.35s ease-out;
      }
    }
    .focus-el-content {
      width: 100%;
      height: 100%;
      display: block;
    }
    .focus-el-content-inner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 7% 9%;
      .title-inner {
        > .title {
          font-size: 38px;
          z-index: 1;
          @media only screen and (min-width: 1280px) {
            font-size: 40px;
          }
          @media only screen and (min-width: 1440px) {
            font-size: 40px;
          }
        }
      }
    }
    &.focus-el-list-layout--link-button {
      &:hover {
        .focus-el-image {
          clip-path: inset(0 0 0 0);
          -webkit-clip-path: inset(0 0 0 0);
        }
      }
    }
    .focus-el-content-inner {
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      padding: 130px 12.5% 50px;

      .focus-el-m-button {
        position: relative;
        margin: 0;
        z-index: 1;
      }
    }
  }
  .focus-el-button {
    &.focus-el--link {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: auto;
      margin: 0;
      letter-spacing: 0.35em;
      font-size: 14px;
      line-height: 2em;
      font-weight: 600;
      text-decoration: none;
      text-transform: uppercase;
      border-radius: 3px;
      outline: 0;
      transition:
        color 0.2s ease-out,
        background-color 0.2s ease-out,
        border-color 0.2s ease-out;
      padding: 12px 49px;
    }
    &.focus-el-list-layout--textual {
      padding: 0 !important;
      color: #262626;
      background-color: transparent;
      border: 0;
    }
  }
`
