import React from 'react'
import { FaEnvelope, FaLine, FaPhoneAlt } from 'react-icons/fa'
import { StyleServiceSocial } from './style'

const ServiceSocial = () => {
  return (
    <StyleServiceSocial className='contact-address'>
      <h3 className='mb-4 mt-8 uppercase lg:mt-0'>Address</h3>
      <div className='grid md:grid-cols-3 md:divide-x-2'>
        <div className='contact-items py-8 text-center'>
          <div className='flex flex-col items-center justify-center space-y-4'>
            <FaLine className='text-primary' size={30} />
            <p>@twinsynergy</p>
          </div>
        </div>
        <div className='contact-items py-8 text-center'>
          <div className='flex flex-col items-center justify-center space-y-4'>
            <FaPhoneAlt className='text-primary' size={30} />
            <p>063-789-9059</p>
          </div>
        </div>
        <div className='contact-items py-8 text-center'>
          <div className='flex flex-col items-center justify-center space-y-4'>
            <FaEnvelope className='text-primary' size={30} />
            <p>info@twinsynergy.co.th</p>
          </div>
        </div>
      </div>
    </StyleServiceSocial>
  )
}

export default ServiceSocial
