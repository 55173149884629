import styled from 'styled-components'

export const StylePartnerBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition:
    background 0.3s,
    border 0.3s,
    border-radius 0.3s,
    box-shadow 0.3s,
    -webkit-box-shadow 0.3s;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #dbdbdb;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #dbdbdb;
  .focus-el-image {
    clip-path: inset(0 100% 0 0);
    -webkit-clip-path: inset(0 100% 0 0);
    transition: -webkit-clip-path 0.5s cubic-bezier(0.31, 0.29, 0.05, 0.96);
    transition: clip-path 0.5s cubic-bezier(0.31, 0.29, 0.05, 0.96);
    transition:
      clip-path 0.5s cubic-bezier(0.31, 0.29, 0.05, 0.96),
      -webkit-clip-path 0.5s cubic-bezier(0.31, 0.29, 0.05, 0.96);
  }
  img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`
