/* eslint-disable @next/next/no-img-element */
import React from 'react'
import { ImgResponsive } from '@/components/elements'

const PageLoading = () => {
  return (
    <div className='__top_side_menu_body fixed left-0 top-0 z-50 h-screen w-full'>
      <div className='bg-primary'>
        <div className='top_screen'>
          <div className='splash_content flex flex-col items-center justify-center'>
            <div className='splash_logo w-full text-center'>
              <ImgResponsive
                src='/images/logotwin-100.webp'
                alt='twin loading'
                width={50}
                height={50}
                PicClassName='inline-block text-center'
              />
            </div>
            <div className='splash_title'>
              <div className='splash_text-content animate_text_delay txtloading'>
                <div className='animate_text animate_text_after'>
                  Getting a quality website is not an expenses but rather an investment.
                </div>
                <div className='animate_text animate_text_before'>
                  Getting a quality website is not an expenses but rather an investment.
                </div>
              </div>
            </div>
            <div className='mt-4 w-full text-center'>
              <span className='loading loading-spinner' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageLoading
