import React from 'react'
import { ImgResponsive } from '@/components/elements'
import { StyleWarrantyItem } from './style'
import classNames from 'classnames'

type WarrantyItemProps = {
  content?: string | React.ReactNode
  serviceImg?: string
  serviceBg?: string
  className?: string
}

const WarrantyItem = (props: WarrantyItemProps) => {
  const { content = '', serviceImg = '', serviceBg = '', className } = props
  const mergeCls = classNames('p-6', className)
  return (
    <StyleWarrantyItem className={mergeCls} serviceBg={`${serviceBg}`}>
      <div className='flex h-full flex-col space-y-4'>
        <div className='grow'>
          <ImgResponsive
            src={serviceImg}
            alt='service'
            width={170}
            height={170}
            PicClassName='flex justify-center items-end h-full'
          />
        </div>
        <div className='content flex-none text-center'>
          <p className='capitalize'>{content}</p>
        </div>
      </div>
    </StyleWarrantyItem>
  )
}

export default WarrantyItem
