import styled from 'styled-components'

export const StyleCustomerLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  // margin: 0 0 40px;
  .customer-image {
    line-height: 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    a {
      display: inline-block;
    }
    .customer-logo {
      width: auto;
      opacity: 1;
      will-change: opacity;
      transition: opacity 0.3s ease;
      display: inline-block;
    }
    .customer-hover-logo {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      opacity: 0;
      will-change: opacity;
      transition: opacity 0.3s ease;
    }
    &:hover {
      .customer-logo {
        opacity: 0;
      }
      .customer-hover-logo {
        opacity: 1;
      }
    }
  }
`
