import styled from 'styled-components'

export const StylePortfolioItem = styled.div<{ serviceBg: string }>`
  border-color: #c7d1d9;
  border: 0;
  border-radius: 0;
  box-shadow:
    1px 0 0 0 #c7d1d9,
    0 1px 0 0 #c7d1d9,
    1px 1px 0 0 #c7d1d9,
    1px 0 0 0 #c7d1d9 inset,
    0 1px 0 0 #c7d1d9 inset;
  transition: all 0.2s;
  &:hover {
    background-color: ${(props) => (props.serviceBg ? props.serviceBg : '#fff')};
    .title-inner {
      h2 {
        color: #fff !important;
      }
    }
  }
`
